* {
  margin: 0;
  padding: 0;
}

#ListModels {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
}

#ListModels a {
  color: #000 !important;
  font-size: 22px;
}

#ListModels a:hover {
  opacity: 0.65;
}

#ListModels li:not(:last-child) {
  margin-bottom: 20px;
}

.hovered {
  cursor: pointer;
}


.annotation{
  transition: opacity 0.45s  ease;
}

.annotation-button {
  font-family: monospace;
  pointer-events: none;
  font-size: 17px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: color 0.45s  ease, border-color 0.45s  ease;
}

.annotation.hovered .annotation-button,
.annotation.selected .annotation-button{
  border-color: cadetblue;
  color: cadetblue;
}

.annotation.behind{
  opacity: 0.25;
}

.annotation-tooltip {
  color: #ffffff;
  font-family: monospace;
  pointer-events: none;
  font-size: 14px;
  position: absolute;
  left: 25px;
  padding: 1em;
  width: 200px;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 0.5em;
  transition: opacity 0.5s;
  display: none;
}

.annotation-title {
  font-weight: bold;
  font-size: 20px;
}